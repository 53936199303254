'use client';

import i18next from 'i18next';
import {
  initReactI18next,
  useTranslation as useTranslationOrg,
} from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { getOptions } from './settings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { useParams } from 'next/navigation';

i18next
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`src/lib/partners/public/locales/${language}/${namespace}.json`),
    ),
  );

export function useTranslation() {
  const partner = usePartner();
  const partnerSettings = getPartnerSettings(partner);
  const params = useParams();
  let langOverride = (params.lang as string).replace('-', '_');

  if (i18next.isInitialized !== true) {
    i18next.init({
      ...getOptions(partner, langOverride ?? partnerSettings.locale),
      preload: [],
    });
  }

  const { t, ...rest } = useTranslationOrg(partner);

  const hasNonEmptyValue = (key: string): boolean => {
    const translation = t(key);
    return translation && translation !== key ? true : false;
  };

  return { hasNonEmptyValue, t, ...rest };
}
